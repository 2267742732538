<!-- =========================================================================================
  File Name: DashboardAnalytics.vue
  Description: Dashboard Analytics

========================================================================================== -->

<template>
  <div class="vx-row">
    <div class="vx-col w-full mb-base">
      <vx-card class="">
        <form-wizard
          :subtitle="null"
          :title="null"
          color="rgba(var(--vs-primary), 1)"
          errorColor="rgba(var(--vs-danger), 1)"
          @on-complete="$router.push(`/a/examedge/banks/${bankData.id}`)"
        >
          <tab-content :before-change="validateStep1" class="mb-5" icon="feather icon-upload" title="Upload Questions">
            <!-- tab 1 content -->
            <vx-card class="d-theme-dark-light-bg mt-1">
              <div class="vx-row items-center rounded">
                <div class="vx-col md:w-1/2 w-full p-10">
                  <h1 class="my-3">Upload Questions</h1>
                  <p>
                    Use this template to fill in the details to mass upload questions to Intellectual Point Connect.<br />Use Microsoft
                    Excel or Google Sheets to edit the template
                  </p>
                  <vs-button
                    class="mt-4 font-bold"
                    color="primary"
                    href="https://cdn.discordapp.com/attachments/746242308084858900/879076944711397376/Question_Template.xlsx"
                    icon="icon-download"
                    icon-pack="feather"
                    type="border"
                    >Download Template
                  </vs-button>
                </div>
                <div class="vx-col md:w-1/2 w-full p-10">
                  <upload-file dropTitle="Drop Questions File or" allowedFileTypes=".xlsx" :onSuccess="this.readerData"></upload-file>
                  <h6 v-if="excelData.success === false && excelData.errorMsg" class="mt-2 text-danger font-semibold">
                    {{ excelData.errorMsg }}
                  </h6>
                  <h6 v-if="excelData.success === true && excelData.results" class="mt-2 text-success font-semibold">
                    Uploaded File Contains {{ excelData.results.length }} Valid Questions
                  </h6>
                </div>
              </div>
            </vx-card>
          </tab-content>
          <tab-content :before-change="validateStep2" class="mb-5" icon="feather icon-edit" title="Edit Questions">
            <vs-table
              :data="excelData.results"
              v-if="excelData.results && excelData.results"
              max-items="10"
              pagination
              search
              :key="excelTableKey"
            >
              <template slot="header">
                <h1 class="ml-4">Uploaded Questions</h1>
              </template>

              <template slot="thead">
                <vs-th>Type</vs-th>
                <vs-th>Question</vs-th>
                <vs-th>Explanation Text</vs-th>
                <vs-th>Correct Answers</vs-th>
                <vs-th>Action</vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr v-for="(attach, index) in data" :key="attach['Question']">
                  <vs-td :data="`${attach['Type']}`" class="truncate" style="max-width: 300px">
                    {{ attach['Type'] }}
                  </vs-td>

                  <vs-td :data="`${attach['Question']}`" class="truncate" style="max-width: 300px">
                    {{ attach['Question'] }}
                  </vs-td>

                  <vs-td :data="`${attach['Explanation Text']}`" class="truncate" style="max-width: 300px">
                    {{ attach['Explanation Text'] }}
                  </vs-td>

                  <vs-td :data="`${attach['Correct Answers']}`" class="truncate" style="max-width: 300px">
                    {{ attach['Correct Answers'] }}
                  </vs-td>

                  <vs-td>
                    <vs-button
                      color="primary"
                      size="small"
                      type="border"
                      icon-pack="feather"
                      icon="icon-edit"
                      @click="editQuestionStart(attach, index)"
                    >
                      Edit
                    </vs-button>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </tab-content>
          <tab-content :before-change="validateStep4" class="mb-5" icon="feather icon-eye" title="Review Details">
            <div class="vx-row">
              <div class="vx-col w-1/2">
                <h1>{{ goodToSubmit ? 'Ready To Create Questions, Press Next To Create' : 'Fix Errors Before Re-Submitting' }}</h1>
                <h3 class="mt-2">Total Questions : {{ excelData.results.length }}</h3>
                <h3 class="mt-2" v-if="goodToSubmitErrors.length !== 0">Total Errors : {{ goodToSubmitErrors.length }}</h3>
                <vs-button class="mt-4" @click="validateStep3" v-if="!goodToSubmit">Re-Check For Errors</vs-button>
              </div>
              <div class="vx-col w-1/2" v-if="goodToSubmitErrors.length !== 0">
                <vs-table :data="goodToSubmitErrors" max-items="5" pagination search>
                  <template slot="header">
                    <h1 class="ml-4">Error List</h1>
                  </template>

                  <template slot="thead">
                    <vs-th>Code</vs-th>
                    <vs-th>Error</vs-th>
                    <vs-th>Item</vs-th>
                  </template>

                  <template slot-scope="{ data }">
                    <vs-tr v-for="(attach, index) in data" :key="index">
                      <vs-td :data="`${attach['code']}`">
                        {{ attach['code'] }}
                      </vs-td>

                      <vs-td :data="`${attach['error']}`">
                        {{ attach['error'] }}
                      </vs-td>

                      <vs-td :data="`${attach['item']}`">
                        {{ attach['item'] }}
                      </vs-td>
                    </vs-tr>
                  </template>
                </vs-table>
              </div>
            </div>
          </tab-content>
          <tab-content class="mb-5" icon="feather icon-check" title="Complete">
            <div class="vx-row">
              <div class="vx-col w-1/2 p-2">
                <h5>Total Warnings : {{ submittedWarnings.length }}</h5>
                <vs-table :data="submittedWarnings" v-if="submittedWarnings.length !== 0" max-items="5" pagination search>
                  <template slot="header">
                    <h1 class="ml-4">Warning List</h1>
                  </template>

                  <template slot="thead">
                    <vs-th>Code</vs-th>
                    <vs-th>Warning</vs-th>
                    <vs-th>Item</vs-th>
                    <vs-th>Details</vs-th>
                  </template>

                  <template slot-scope="{ data }">
                    <vs-tr v-for="(attach, index) in data" :key="index">
                      <vs-td :data="`${attach['code']}`">
                        {{ attach['code'] }}
                      </vs-td>

                      <vs-td :data="`${attach['warning']}`">
                        {{ attach['warning'] }}
                      </vs-td>

                      <vs-td :data="`${attach['details']}`">
                        {{ attach['details'] }}
                      </vs-td>

                      <vs-td :data="`${attach['item']}`">
                        {{ attach['item'] }}
                      </vs-td>
                    </vs-tr>
                  </template>
                </vs-table>
              </div>
              <div class="vx-col w-1/2 p-2">
                <h5>Total Warnings : {{ submittedErrors.length }}</h5>
                <vs-table :data="submittedErrors" v-if="submittedErrors.length !== 0" max-items="5" pagination search>
                  <template slot="header">
                    <h1 class="ml-4">Error List</h1>
                  </template>

                  <template slot="thead">
                    <vs-th>Code</vs-th>
                    <vs-th>Error</vs-th>
                    <vs-th>Item</vs-th>
                  </template>

                  <template slot-scope="{ data }">
                    <vs-tr v-for="(attach, index) in data" :key="index">
                      <vs-td :data="`${attach['code']}`">
                        {{ attach['code'] }}
                      </vs-td>

                      <vs-td :data="`${attach['error']}`">
                        {{ attach['error'] }}
                      </vs-td>

                      <vs-td :data="`${attach['item']}`">
                        {{ attach['item'] }}
                      </vs-td>
                    </vs-tr>
                  </template>
                </vs-table>
              </div>
            </div>
            <div class="vx-col w-full mt-3">
              <h1>Successful Creations : {{ submittedSuccess }}</h1>
              <h1>Upload Count : {{ excelData.results.length }}</h1>
            </div>
          </tab-content>
        </form-wizard>
      </vx-card>
    </div>
    <vs-popup :active.sync="editSingleQuestionActive" title="Edit Question Values" id="EditQuestionPopup">
      <vs-input v-model="editSingleQuestion.question" class="mb-8 w-full" label-placeholder="Question" />
      <vs-button color="success" type="filled" @click="editQuestionSave">Save Changes</vs-button>
    </vs-popup>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import UploadFile from '@/components/upload/UploadFile';
import * as XLSX from 'xlsx';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

export default {
  data() {
    return {
      bankData: null,

      excelTableKey: 0,
      excelData: {
        success: false,
        errorMsg: '',
        header: null,
        results: [],
        meta: null,
      },

      editSingleQuestionActive: false,
      editSingleQuestion: {
        question: '',
      },

      goodToSubmit: true,
      goodToSubmitErrors: [],

      submittedQuestions: false,
      submittedWarnings: [],
      submittedErrors: [],
      submittedSuccess: 0,
    };
  },
  methods: {
    setInit(id) {
      this.$http.get(`examedge/bank/${id}`).then((response) => {
        if (response.data) {
          this.bankData = response.data;
        }
      });
    },
    editQuestionSave() {
      this.excelData.results.splice(this.editSingleQuestion.index, 1);

      this.excelData.results.unshift({
        Question: this.editSingleQuestion.question,
        'Question Extended': this.editSingleQuestion.questionExtended,
        'Question Image': this.editSingleQuestion.questionImage,
        Definition: this.editSingleQuestion.definition,
        'Definition Extended': this.editSingleQuestion.definitionExtended,
        'Definition Image': this.editSingleQuestion.definitionImage,
      });

      this.editSingleQuestionActive = false;
      this.excelTableKey++;
    },
    editQuestionStart(question, index) {
      this.editSingleQuestion = {
        question: question['Question'],
        index,
      };
      this.editSingleQuestionActive = true;
    },
    excelError(msg) {
      this.excelData.success = false;
      this.excelData.errorMsg = msg;

      this.$vs.notify({
        title: 'Sheet Parsing Error',
        text: msg,
        color: 'danger',
        iconPack: 'feather',
        position: 'bottom-right',
        icon: 'icon-alert-circle',
      });
    },
    excelSuccess(msg) {
      this.excelData.success = true;

      this.$vs.notify({
        title: 'File Uploaded Successfully',
        text: msg,
        color: 'success',
        iconPack: 'feather',
        position: 'bottom-right',
        icon: 'icon-check-circle',
      });
    },
    getHeaderRow(sheet) {
      const headers = [];
      const range = XLSX.utils.decode_range(sheet['!ref']);
      let C = undefined;
      const R = range.s.r;
      /* start in the first row */
      for (C = range.s.c; C <= range.e.c; ++C) {
        /* walk every column in the range */
        const cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })];
        /* find the cell in the first row */
        let hdr = `UNKNOWN ${C}`; // <-- replace with your desired default
        if (cell && cell.t) hdr = XLSX.utils.format_cell(cell);
        headers.push(hdr);
      }
      return headers;
    },
    generateData({ header, results, meta }) {
      if (!header.includes('Type')) {
        return this.excelError('Missing "Type" Column, Please Use The Template File.');
      }

      if (!header.includes('Question')) {
        return this.excelError('Missing "Question" Column, Please Use The Template File.');
      }

      if (!header.includes('Explanation Text')) {
        return this.excelError('Missing "Explanation Text" Column, Please Use The Template File.');
      }

      if (!header.includes('Correct Answers')) {
        return this.excelError('Missing "Correct Answers" Column, Please Use The Template File.');
      }

      if (!header.includes('Option 1')) {
        return this.excelError('Missing "Option 1" Column, Please Use The Template File.');
      }

      if (!header.includes('Option 2')) {
        return this.excelError('Missing "Option 2" Column, Please Use The Template File.');
      }

      if (!header.includes('Option 3')) {
        return this.excelError('Missing "Option 3" Column, Please Use The Template File.');
      }

      if (!header.includes('Option 4')) {
        return this.excelError('Missing "Option 4" Column, Please Use The Template File.');
      }

      if (!header.includes('Option 5')) {
        return this.excelError('Missing "Option 5" Column, Please Use The Template File.');
      }

      if (!header.includes('Option 6')) {
        return this.excelError('Missing "Option 6" Column, Please Use The Template File.');
      }

      if (!header.includes('Option 7')) {
        return this.excelError('Missing "Option 7" Column, Please Use The Template File.');
      }

      if (!header.includes('Option 8')) {
        return this.excelError('Missing "Option 8" Column, Please Use The Template File.');
      }

      if (!header.includes('Option 9')) {
        return this.excelError('Missing "Option 9" Column, Please Use The Template File.');
      }

      if (!header.includes('Option 10')) {
        return this.excelError('Missing "Option 10" Column, Please Use The Template File.');
      }

      if (results.length <= 0) {
        return this.excelError('File Is Empty, Please Fill Out Values Before Uploading.');
      }

      this.excelSuccess('File Uploaded & Parsed Successfully');
      this.excelData.header = header;
      this.excelData.results = results;
      this.excelData.meta = meta;
    },
    readerData(rawFile) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: 'array' });
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
          const header = this.getHeaderRow(worksheet);
          const results = XLSX.utils.sheet_to_json(worksheet);
          const meta = { sheetName: firstSheetName };
          this.generateData({ header, results, meta });
          resolve();
        };
        reader.readAsArrayBuffer(rawFile);
      });
    },
    validateStep1() {
      return new Promise((resolve, reject) => {
        if (this.excelData.success === true) {
          resolve(true);
        } else {
          this.$vs.notify({
            title: 'No File Uploaded',
            text: 'You must upload a file before continuing',
            color: 'danger',
            iconPack: 'feather',
            position: 'bottom-right',
            icon: 'icon-alert-circle',
          });
          reject('correct all values');
        }
      });
    },
    validateStep2() {
      return new Promise((resolve) => {
        resolve(true);
      });
    },
    validateStep3() {
      return new Promise((resolve) => {
        this.goodToSubmitErrors = [];
        this.goodToSubmit = true;

        resolve(true);
      });
    },
    validateStep4() {
      return new Promise((resolve, reject) => {
        if (this.goodToSubmit !== true) {
          this.$vs.notify({
            title: 'Outstanding Errors',
            text: 'Please resolve all errors before submitting',
            color: 'danger',
            position: 'top-right',
          });
          return reject('errors');
        }

        if (this.submittedQuestions !== false) {
          this.$vs.notify({
            title: 'Already Submitted Questions',
            text: 'Please refresh the page to start again.',
            color: 'danger',
            position: 'top-right',
          });
          return reject('errors');
        }

        const pl = {
          questions: this.excelData.results,
        };

        this.$vs.loading();

        this.$http
          .patch(`examedge/bank/${this.bankData.id}/upload`, pl)
          .then((response) => {
            if (response.status === 200) {
              this.$vs.loading.close();

              this.submittedErrors = response.data.data.errors;
              this.submittedSuccess = response.data.data.success;
            }
          })
          .catch((exception) => {
            this.$vs.loading.close();
            this.goodToSubmit = false;

            this.goodToSubmitErrors.push({
              code: 'FAILURE',
              error: exception.response.data.error.description,
            });

            return this.$vs.notify({
              title: 'Errors With Submission',
              text: 'No Questions Have Been Created',
              color: 'danger',
              position: 'top-right',
            });
          });

        resolve(true);
      });
    },
  },
  mounted() {
    this.setInit(this.$route.query.bank);
  },
  components: {
    UploadFile,
    FormWizard,
    TabContent,
    XLSX,
    VueJsonPretty,
  },
};
</script>
<style lang="scss">
.theme-dark {
  #EditQuestionPopup {
    input.vs-inputx.vs-input--input {
      background: #1b1e22 !important;
      border: 1px solid #ffffff !important;

      &:focus {
        border: 1px solid rgba(var(--vs-primary), 1) !important;
      }
    }
  }
}
</style>
